<div class="p-0 m-0 h-100 bg-light">
  <div class="d-flex flex-column mt-5">
    <div class="d-flex flex-row px-5 my-2">
      <i class="bi bi-folder-fill h1"></i>
      <h1 class="fw-bold mx-2">
        {{ "SITE.SITE_IN" | translate }}
        <span class="my-auto" *ngIf="(site?.projectName?.length || '') <= 40">
          {{ shrinkString(site?.projectName) }}
        </span>
        <span
          class="my-auto"
          [mdbTooltip]="site?.projectName || ''"
          *ngIf="(site?.projectName?.length || '') >= 40"
        >
          {{ shrinkString(site?.projectName) }}
        </span>
      </h1>
    </div>
    <div class="d-flex flex-column sticky-top bg-light px-5">
      <div class="d-flex flex-row mt-4">
        <div style="height: 6rem; width: 6rem" *ngIf="site?.ref">
          <img
            class="w-100 h-100"
            src="../../../assets/images/{{ site?.ref?.toLowerCase() }}.svg"
            style="object-fit: contain"
          />
        </div>
        <div class="mx-2 d-flex flex-column justify-content-center">
          <div class="d-flex flex-row align-items-center">
            <h3 class="my-auto" *ngIf="(site?.name?.length || '') <= 40">
              {{ shrinkString(site?.name) }}
            </h3>
            <h3
              class="my-auto"
              [mdbTooltip]="site?.name || ''"
              *ngIf="(site?.name?.length || '') >= 40"
            >
              {{ shrinkString(site?.name) }}
            </h3>
            <span
              class="mx-3 my-auto"
              style="cursor: pointer"
              (click)="backToProject()"
            >
              <u> {{ "SITE.BACK_TO_PROJECT" | translate }}</u>
            </span>
          </div>
          <h5 class="my-auto">
            {{ site?.province }} |
            {{ getAgroZone(site) }}
          </h5>
          <h6 class="my-auto">
            {{ site?.country }}
          </h6>
        </div>
      </div>
      <div class="d-flex d-row py-0 my-0 mt-3">
        <!-- Buttons -->
        <horizontal-list-button
          name="SITE.ACTIVITIES"
          routerLink="activities"
        ></horizontal-list-button>

        <horizontal-list-button
          name="SITE.MAP"
          routerLink="map"
        ></horizontal-list-button>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
