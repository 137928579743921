<div class="card" style="height: 15rem; overflow: hidden">
  <div class="w-100 h-100">
    <div
      class="position-absolute bottom-0 end-0"
      style="height: 9rem; width: 9rem"
    >
      <img [src]="getImage()" style="object-fit: contain; opacity: 0.25" />
    </div>
  </div>
  <div class="mask d-flex flex-column px-3 py-1">
    <div class="d-flex" style="height: 5rem">
      <h3
        class="my-auto h3"
        [ngClass]="project?.purpose === 'TEST' ? 'text-test' : 'text-primary'"
        *ngIf="(project?.title?.length || '') <= 60"
      >
        {{ shrinkString(project?.title) }}
      </h3>
      <h3
        class="my-auto h3"
        [ngClass]="project?.purpose === 'TEST' ? 'text-test' : 'text-primary'"
        mdbTooltip="{{ project?.title }}"
        *ngIf="(project?.title?.length || '') >= 60"
      >
        {{ shrinkString(project?.title) }}
      </h3>
    </div>
    <div
      [ngClass]="project?.purpose === 'TEST' ? 'bg-test' : 'bg-primary'"
      style="height: 2.5px"
    ></div>
    <div class="d-flex flex-row justify-content-between">
      <h5 class="text-secondary py-2">
        {{ "PROJECTS." + project?.projectRole | translate }}
      </h5>
      <h5 class="text-secondary py-2">
        <span class="" *ngIf="!project?.own">
          {{ "PROJECTS.SHARE_PROJECT" | translate }}
        </span>
        {{ project?.purpose === "TEST" ? "TEST ID" : "ID" }}:
        {{ project?.uuid }}
      </h5>
    </div>
    <h6 class="text-secondary">{{ project?.description }}</h6>
    <div class="d-flex flex-row justify-content-between mt-auto mb-2">
      <button
        class="btn btn-primary bg-danger"
        (click)="deleteProject(project || null)"
      >
        {{ "BUTTONS.DELETE" | translate }}
      </button>
      <a
        class="btn btn-primary bg-primary"
        [routerLink]="['/u/project', getRefPath(), project?.projectID]"
        *ngIf="project?.projectRole != 'DATA_ENTRY'"
      >
        {{ "BUTTONS.OPEN" | translate }}
      </a>
    </div>
  </div>
</div>
