import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbTableModule } from 'mdb-angular-ui-kit/table';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbVectorMapModule } from 'mdb-angular-vector-maps';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbStickyModule } from 'mdb-angular-ui-kit/sticky';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbSidenavModule } from 'mdb-angular-ui-kit/sidenav';
import { MdbChartModule } from 'mdb-angular-ui-kit/charts';
import { MdbStepperModule } from 'mdb-angular-ui-kit/stepper';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { NgxMasonryModule } from 'ngx-masonry';
import { NgxGaugeModule } from 'ngx-gauge';
import { MdbPopconfirmModule } from 'mdb-angular-ui-kit/popconfirm';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MdbSelectModule,
    MdbModalModule,
    MdbTableModule,
    MdbPopoverModule,
    MdbVectorMapModule,
    MdbTooltipModule,
    MdbTabsModule,
    MdbAccordionModule,
    MdbStickyModule,
    MdbCarouselModule,
    MdbSidenavModule,
    MdbChartModule,
    MdbStepperModule,
    MdbCheckboxModule,
    MdbDatepickerModule,
    NgxGaugeModule,
    MdbPopconfirmModule,
    NgSelectModule,
  ],
  exports: [
    MdbSelectModule,
    MdbModalModule,
    MdbTableModule,
    MdbPopoverModule,
    MdbVectorMapModule,
    MdbTooltipModule,
    MdbTabsModule,
    MdbAccordionModule,
    MdbStickyModule,
    MdbCarouselModule,
    MdbSidenavModule,
    MdbChartModule,
    MdbStepperModule,
    MdbCheckboxModule,
    MdbDatepickerModule,
    NgxMasonryModule,
    NgxGaugeModule,
    MdbPopconfirmModule,
    NgSelectModule,
  ],
})
export class MdbModule {}
