import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  circle,
  LatLng,
  latLng,
  latLngBounds,
  Marker,
  marker,
  polygon,
  popup,
  tileLayer,
  Map,
  icon,
} from 'leaflet';
import { ApiService } from 'src/app/api.service';

export interface Activity {
  activity: string;
  lat: number;
  lng: number;
  alt: number;
  uuid: string;
}

@Component({
  selector: 'project-map',
  templateUrl: './project-map.component.html',
  styleUrls: ['./project-map.component.scss'],
})
export class ProjectMapComponent implements OnInit {
  options = {
    layers: [tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png')],
    zoom: 15.5,

    filter: ['grayScale'],
    center: latLng([0, 0]),
  };

  activities?: Activity[];

  markers: any[] = [];

  constructor(private api: ApiService, private route: ActivatedRoute) {}

  ngOnInit(): void {}

  mapReady(map: Map) {
    const id = this.route.parent?.snapshot.paramMap.get('id') as string;
    this.api.getActivitiesPoints(id).subscribe((data) => {
      const activities = data as Activity[];
      const points: LatLng[] = [];
      activities.forEach((item: Activity) => {
        const m = marker([item.lat, item.lng], {
          icon: icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: '/assets/images/map-icons/' + item.activity + '.png',
          }),
        }).bindPopup("<h6 class='mx-auto'>" + item.uuid + '</h6>', {
          closeButton: false,
        });
        const p = latLng(item.lat, item.lng);
        points.push(p);
        this.markers?.push(m);
      });
      const bounds = latLngBounds(points);
      map.fitBounds(bounds);
    });
  }

  //   layersControl = {
  //   baseLayers: {
  //     Map: tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  //       minZoom: 15.5,
  //     }),
  //   },
  //   overlays: {
  //     // 'Big Circle': circle([42.09609, 12.42863], {
  //     //   radius: 500,
  //     //   fillColor: '#00000',
  //     // }),
  //     'Focus group': polygon(
  //       [
  //         [42.09825, 12.42512],
  //         [42.09787, 12.43567],
  //         [42.09307, 12.43065],
  //         [42.09307, 12.42178],
  //       ],
  //       { color: 'red', fillColor: 'red' }
  //     ),
  //   },
  // };
}
