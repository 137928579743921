import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { ModalPeriodFileRequestedComponent } from 'src/app/modals/projects/modal-period-file-requested/modal-period-file-requested.component';
import { ModalFileRequestedComponent } from 'src/app/modals/projetcs/modal-file-requested/modal-file-requested.component';
import { FileCreated } from 'src/app/models/file-created';
import { RequestFile } from 'src/app/models/request-file';
import { RequestFileCategories } from '../project-data-collected-list/project-data-collected-list.component';

@Component({
  selector: 'period-data-collected',
  templateUrl: './period-data-collected.component.html',
  styleUrls: ['./period-data-collected.component.scss'],
})
export class PeriodDataCollectedComponent implements OnInit {
  filterForm?: UntypedFormGroup;
  requestFileForm?: UntypedFormGroup;

  lang$?: Subscription;

  periodID?: string;
  fileType: string = 'xlsx';

  requesGeneralFiles: RequestFile[] = [];
  requesFGDFiles: RequestFile[] = [];
  requesHHSFiles: RequestFile[] = [];
  requesKISFiles: RequestFile[] = [];

  fileCreated?: FileCreated[];

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private modalService: MdbModalService
  ) {}

  ngOnInit(): void {
    this.filterForm = new UntypedFormGroup({
      filter: new UntypedFormControl(null, []),
      filterFile: new UntypedFormControl(null, []),
    });

    this.requestFileForm = new UntypedFormGroup({
      select: new UntypedFormControl(null, []),
    });

    this.periodID = this.route.parent?.snapshot.paramMap.get('id') as string;

    this.apiCall();

    this.filter?.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      if (this.periodID)
        this.api
          .getPeriodFiles(this.periodID, this.filter?.value)
          .subscribe((value) => {
            const requestCategory = value as RequestFileCategories;
            this.requesFGDFiles = requestCategory.FGD;
            if (
              this.requesFGDFiles[0] &&
              this.requesFGDFiles[0].species.length == 1
            ) {
              this.select?.setValue(this.requesFGDFiles[0].species[0].refID);
            }
            this.requesHHSFiles = requestCategory.HHS;
            this.requesKISFiles = requestCategory.KIS;
            this.requesGeneralFiles = requestCategory.GENERAL;
          });
    });

    this.filterFile?.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      if (this.periodID)
        this.api
          .getPeriodFileCreated(this.periodID, false, this.filterFile?.value)
          .subscribe((value) => {
            this.fileCreated = value as FileCreated[];
            // this.fileCreated = list.reverse();
          });
    });

    this.lang$ = this.translate.onLangChange.subscribe(() => {
      this.apiCall();
    });
  }

  ngOnDestroy(): void {
    this.lang$?.unsubscribe();
  }

  apiCall() {
    if (this.periodID) {
      this.api
        .getPeriodFiles(this.periodID, this.filter?.value)
        .subscribe((value) => {
          const requestCategory = value as RequestFileCategories;
          this.requesFGDFiles = requestCategory.FGD;
          if (this.requesFGDFiles[0].species.length == 1) {
            this.select?.setValue(this.requesFGDFiles[0].species[0].refID);
          }
          this.requesHHSFiles = requestCategory.HHS;
          this.requesKISFiles = requestCategory.KIS;
          this.requesGeneralFiles = requestCategory.GENERAL;
        });
      this.api
        .getPeriodFileCreated(this.periodID, false, this.filterFile?.value)
        .subscribe((value) => {
          this.fileCreated = value as FileCreated[];
          // this.fileCreated = list.reverse();
        });
    }
  }

  requestAgain(refID: string, index: number) {
    this.api.requestAgainFile(refID, false).subscribe((value) => {
      this.fileCreated![index] = value as FileCreated;
    });
  }

  openRequestedFile(
    fileExt: string,
    projectFileTypeID: string,
    refID: string,
    speciesID: string | null
  ) {
    this.modalService.open(ModalPeriodFileRequestedComponent, {
      modalClass: 'modal-lg',
      data: {
        fileExt: fileExt,
        projectFileTypeID: projectFileTypeID,
        refID: refID,
        speciesID: speciesID,
        cleaned: false,
      },
    });
  }

  selectFileTypeHHS(i: number, ext: string) {
    this.requesHHSFiles[i].selectedExtension = ext;
  }

  selectFileTypeFGD(i: number, ext: string) {
    this.requesFGDFiles[i].selectedExtension = ext;
  }

  selectFileTypeGeneral(i: number, ext: string) {
    this.requesGeneralFiles[i].selectedExtension = ext;
  }

  get filter() {
    return this.filterForm?.get('filter');
  }

  get filterFile() {
    return this.filterForm?.get('filterFile');
  }

  get select() {
    return this.requestFileForm?.get('select');
  }
}
