<!-- INITIAL SELECTION -->
<div
  class="d-flex flex-column align-items-center p-4"
  style="height: 70vh"
  *ngIf="state == 0 && (ref === 'c' || ref === 'l')"
>
  <img
    src="/assets/images/logo.png"
    class="img-fluid mb-2"
    style="width: 20rem; height: 20rem; object-fit: contain"
  />
  <h4 class="mb-4">{{ "PERIOD.CLEANING_DISCLAIMER" | translate }}</h4>
  <div
    class="d-flex flex-column justify-content-around align-items-center w-100"
  >
    <div class="btn btn-primary w-25 mb-2" (click)="changeState(1)">
      {{ "PERIOD.DISABLE_ACTIVITIES" | translate }}
    </div>
    <div
      class="btn btn-primary w-25"
      (click)="changeState(2)"
      *ngIf="ref === 'c'"
    >
      {{ "PERIOD.OT_CLEANING" | translate }}
    </div>
    <div class="w-25 flex flex-column align-items-center">
      <button
        class="btn btn-primary w-100 mb-2"
        (click)="changeState(2)"
        *ngIf="ref !== 'c'"
        [disabled]="true"
      >
        {{ "PERIOD.OT_CLEANING" | translate }}
      </button>
      <p class="text-center text-danger">
        {{ "PERIOD.OT_CLEANING_WIP" | translate }}
      </p>
    </div>
  </div>
</div>

<work-in-progress
  *ngIf="state == 0 && !(ref === 'c' || ref === 'l')"
></work-in-progress>

<!-- ACTIVITY CLEANING -->

<div
  class="w-100 h-100 d-flex justify-content-center pt-5"
  *ngIf="loading && state == 1"
>
  <loading [glass]="false"></loading>
</div>
<div class="d-flex flex-column px-5 pb-5" *ngIf="!loading && state == 1">
  <div
    class="d-flex flex-wrap mb-4 bg-light w-100 sticky-top py-3"
    [formGroup]="filterForm!"
  >
    <div class="me-4">
      <ng-select
        [placeholder]="'PLACEHOLDER.SITE' | translate"
        formControlName="siteID"
        [searchable]="false"
        [clearable]="false"
      >
        <ng-option *ngFor="let site of sites" [value]="site.value">
          {{ site.label }}
        </ng-option>
      </ng-select>
    </div>
    <div class="me-4">
      <ng-select
        [placeholder]="'PLACEHOLDER.ACTIVITY' | translate"
        formControlName="activityType"
        [searchable]="false"
        [clearable]="false"
      >
        <ng-option
          *ngFor="let activity of activitiesList"
          [value]="activity.value"
        >
          {{ activity.label }}
        </ng-option>
      </ng-select>
    </div>
    <div class="btn btn-primary" (click)="changeState(0)">
      {{ "BUTTONS.BACK" | translate }}
    </div>
  </div>
  <div class="d-flex flex-wrap">
    <div
      class="card col-3 mb-3 me-3"
      [ngClass]="activity.deleted ? 'bg-danger-opacity' : ''"
      *ngFor="let activity of activities; index as i"
      style="height: 13rem; width: 25rem"
    >
      <div class="d-flex flex-column p-3">
        <div style="overflow-y: auto; height: 2.7rem">
          <h3 class="mb-2">{{ activity.name || activity.location }}</h3>
        </div>
        <h6 class="mb-2 text-secondary">{{ activity.uuid }}</h6>
        <div style="overflow-y: auto; height: 2rem">
          <h5 class="mb-2">{{ activity.location }}</h5>
        </div>
        <h5 class="mb-3">{{ activity.type }}</h5>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <span>{{ activity.date.split("T")[0] }}</span>
          <div class="d-flex ms-auto">
            <div
              class="btn"
              [ngClass]="activity.deleted ? 'btn-success' : 'btn-danger'"
              (click)="
                openSwitchState(
                  activity.activityID,
                  activity.type,
                  activity.deleted,
                  i
                )
              "
            >
              {{
                (activity.deleted ? "BUTTONS.ENABLE" : "BUTTONS.DISABLE")
                  | translate
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- OT CLEANING -->

<div class="d-flex flex-column px-5 pb-5" *ngIf="!loading && state == 2">
  <div class="d-flex mb-4 bg-light w-100 sticky-top py-3">
    <div class="btn btn-primary" (click)="changeState(0)">
      {{ "BUTTONS.BACK" | translate }}
    </div>
  </div>
  <div class="row container-fluid gap-4 mb-4">
    <div
      class="col-4 d-flex flex-column align-items-center card p-2 mb-2"
      style="width: 250px"
    >
      <h5>{{ "OT_CLEANING.CLEANING" | translate }}</h5>
      <div
        class="rounded-circle mb-2"
        style="height: 7rem; width: 7rem; overflow: hidden"
      >
        <img [src]="cropImg" class="img-fluid" />
      </div>
      <div class="w-100" [formGroup]="filterForm!" *ngIf="crops">
        <span>{{ "OT_CLEANING.SELECT_CROP" | translate }}</span>
        <ng-select class="w-100" formControlName="crop" [clearable]="false">
          <ng-option *ngFor="let c of crops" [value]="c.value">
            {{ c.label }}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex flex-wrap col-8" *ngIf="otsFamily">
      <div
        class="card col-3 mb-3 me-3"
        *ngFor="let ot of otsFamily; index as i"
        style="height: 11rem; width: 25rem"
      >
        <div class="d-flex flex-column p-3">
          <div
            class="d-flex flex-column"
            style="overflow-y: auto; height: 6.5rem"
          >
            <h4 class="">{{ ot.title }}</h4>
            <span class="">{{ ot.subtitle }}</span>
            <span class="">{{ ot.user }}</span>
            <span class="">{{ ot.date | date : "MMM dd, yyyy HH:mm" }}</span>
          </div>
          <div
            class="d-flex flex-row justify-content-between align-items-center"
          >
            <!-- <span>{{ activity.date.split("T")[0] }}</span> -->
            <div class="d-flex ms-auto">
              <div
                class="btn btn-primary"
                (click)="
                  openOtCleaning(
                    ot.urlStructure,
                    ot.otCleaningID,
                    ot.type,
                    ot.title
                  )
                "
              >
                {{ "OT_CLEANING.CLEAN" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr class="mt-2" style="border-bottom: solid 3px black" />
  <div class="row container-fluid gap-4 mt-3">
    <div
      class="col-4 d-flex flex-column align-items-center card p-2 mb-2"
      style="width: 250px"
    >
      <h5>{{ "OT_CLEANING.CLEANING" | translate }}</h5>
      <div
        class="rounded-circle mb-2"
        style="height: 7rem; width: 7rem; overflow: hidden"
      >
        <img src="/assets/images/logo.png" class="img-fluid" />
      </div>
      <div class="w-100" [formGroup]="filterForm!" *ngIf="crops">
        <span>{{ "OT_CLEANING.SELECT_SITE" | translate }}</span>
        <ng-select
          class="w-100 mb-2"
          formControlName="otSite"
          [clearable]="false"
        >
          <ng-option *ngFor="let s of sites" [value]="s.value">
            {{ s.label }}
          </ng-option>
        </ng-select>

        <span>{{ "OT_CLEANING.SELECT_FARMER" | translate }}</span>
        <ng-select
          class="w-100 mb-2"
          formControlName="farmer"
          [clearable]="false"
        >
          <ng-option *ngFor="let f of farmers" [value]="f.value">
            <div [title]="f.label">
              {{ f.label }}
            </div>
          </ng-option>
        </ng-select>

        <span>{{ "OT_CLEANING.SELECT_MEASURE" | translate }}</span>
        <ng-select class="w-100" formControlName="measure" [clearable]="false">
          <ng-option [value]="'mq'">
            {{ "OT_CLEANING.MQ" | translate }}
          </ng-option>
          <ng-option [value]="'ha'">
            {{ "OT_CLEANING.HA" | translate }}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="d-flex flex-wrap col-8" *ngIf="otsFarmer">
      <div
        class="card col-3 mb-3 me-3"
        *ngFor="let ot of otsFarmer; index as i"
        style="height: 11rem; width: 25rem"
      >
        <div class="d-flex flex-column p-3">
          <div
            class="d-flex flex-column"
            style="overflow-y: auto; height: 6.5rem"
          >
            <h4 class="">{{ ot.title }}</h4>
            <span class="">{{ ot.subtitle }}</span>
            <span class="">{{ ot.user }}</span>
            <span class="">{{ ot.date | date : "MMM dd, yyyy HH:mm" }}</span>
          </div>

          <div class="d-flex flex-row justify-content-end align-items-center">
            <div
              class="btn btn-primary"
              (click)="
                openOtCleaningFarmer(
                  ot.urlStructure,
                  ot.otCleaningID,
                  ot.type,
                  ot.title
                )
              "
              [ngClass]="farmer?.value ? '' : 'disabled'"
            >
              {{ "OT_CLEANING.CLEAN" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
